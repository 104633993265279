import { Modal as SemanticModal, StrictModalProps } from "semantic-ui-react";
import { X, XCircle } from "react-feather";

import Card from "../UI/Card/Card";

import "./Modal.scss";

export interface ModalProps extends StrictModalProps {
	title?: string;
	component?: JSX.Element;
	nested?: boolean;
}

export default function Modal({ title, nested = false, ...props }: ModalProps) {
	return (
		<SemanticModal
			open={true}
			closeIcon={nested ? <XCircle className="close-icon" /> : <X className="close-icon" />}
			size={nested ? "small" : props.size ?? undefined}
			className={nested ? "modal-nested" : ""}
			{...props}
		>
			<Card title={title} className={nested ? "modal-card modal-nested" : "modal-card"}>
				{props.children}
			</Card>
		</SemanticModal>
	);
}
