import React from "react";
import { FormCheckboxProps, Checkbox as SemanticCheckbox } from "semantic-ui-react";
import PropTypes from "prop-types";

import "./Checkbox.scss";

interface CheckboxProps extends FormCheckboxProps
{
	epcRatingGrade?: string;
	labelSide?: "left" | "right";
}

const Checkbox = (props: CheckboxProps) => {
	const { epcRatingGrade, labelSide, ...others } = props;
	let color;

	if (epcRatingGrade) color = "story-epc-rating-grade-" + epcRatingGrade.toLowerCase();
	else if (!color) color = "default";

	if (labelSide === "left") {
		others.className = `${props.className} label-left`;
	} // right is default

	return <SemanticCheckbox className={color ?? "story-checkbox-default"} {...others} />;
};

export default Checkbox;

Checkbox.propTypes = {
	/**
	 * Which color should the checkbox be?
	 **/
	epcRatingGrade: PropTypes.oneOf(["A", "B", "C", "D", "E", "F", "G"]),
};
