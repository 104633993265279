import DirectionArrowVector from "../../../assets/DirectionArrowVector";
import { SapImprovementNiceNames, SapImprovementTypes, sapImprovementUnitsMap } from "../../../types/types";
import ChipLabel from "../../UI/ChipLabel/ChipLabel";

interface SmartTargetValueChipProps {
	type: keyof SapImprovementTypes;
	value: number | null;
	direction: boolean | null;
}

export default function SmartTargetValueChip({ type, value, direction }: SmartTargetValueChipProps) {
	const unit = sapImprovementUnitsMap[type].unit;
	const chipVariant = direction === true ? "positive" : direction === false ? "negative" : "neutral";
	const icon =
		direction === true ? (
			<DirectionArrowVector />
		) : direction === false ? (
			<DirectionArrowVector rotation={180} colour="#DB042C" />
		) : null;

	const generateLabel = () => {
		if (value === null) {
			return `${SapImprovementNiceNames[type]}: N/A`;
		}

		return unit === "£"
			? `${SapImprovementNiceNames[type]}: £${value.toFixed(2)}`
			: unit === null
				? `${SapImprovementNiceNames[type]}: ${value.toFixed(0)}`
				: `${SapImprovementNiceNames[type]}: ${value.toFixed(0)} ${unit}`;
	};

	return <ChipLabel label={generateLabel()} rounded="square" variant={chipVariant} icon={icon} />;
}
