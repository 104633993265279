import AirHeatPumpVector from "../../../assets/CustomImprovementIcons/AirHeatPumpVector";
import BlockChimneyVector from "../../../assets/CustomImprovementIcons/BlockChimneyVector";
import BoilerFlameVector from "../../../assets/CustomImprovementIcons/BoilerFlameVector";
import ConvertArrowsVector from "../../../assets/CustomImprovementIcons/ConvertArrowsVector";
import DoorVector from "../../../assets/CustomImprovementIcons/DoorVector";
import DoubleGlazingVector from "../../../assets/CustomImprovementIcons/DoubleGlazingVector";
import DraughtWindVector from "../../../assets/CustomImprovementIcons/DraughtWindVector";
import ExternalWallInsulationVector from "../../../assets/CustomImprovementIcons/ExternalWallInsulationVector";
import FloorVentsVector from "../../../assets/CustomImprovementIcons/FloorVentsVector";
import GroundHeatPumpVector from "../../../assets/CustomImprovementIcons/GroundHeatPumpVector";
import HeatingControlsVector from "../../../assets/CustomImprovementIcons/HeatingControlsVector";
import LightingVector from "../../../assets/CustomImprovementIcons/LightingVector";
import LoftInsulationVector from "../../../assets/CustomImprovementIcons/LoftInsulationVector";
import PartyWallInsulationVector from "../../../assets/CustomImprovementIcons/PartyWallInsulationVector";
import PerformanceGlazingVector from "../../../assets/CustomImprovementIcons/PerfomanceGlazingVector";
import PvVector from "../../../assets/CustomImprovementIcons/PvVector";
import SmartHeatingControlsVector from "../../../assets/CustomImprovementIcons/SmartHeatingControlsVector";
import SolarHeatingVector from "../../../assets/CustomImprovementIcons/SolarHeatingVector";
import StorageHeatersVector from "../../../assets/CustomImprovementIcons/StorageHeatersVector";
import TripleGlazingVector from "../../../assets/CustomImprovementIcons/TripleGlazingVector";
import UnderfloorInsulationVector from "../../../assets/CustomImprovementIcons/UnderfloorInsulationVector";
import WallInsulationVector from "../../../assets/CustomImprovementIcons/WallInsulationVector";
import WaterInsulationVector from "../../../assets/CustomImprovementIcons/WaterInsulationVector";
import {
	CustomImprovementSetHasCustomImprovement,
	SapImprovementResults,
	SmartTargetFields,
} from "../../../types/types";
import { CardItem } from "../../UI/Card/CardItem";
import SmartTargetValueChip from "./SmartTargetValueChip";
import Property from "../../../rest/Property";

import "./SmartTargetResultItem.scss";

interface SmartTargetResultItemProps {
	improvement: CustomImprovementSetHasCustomImprovement;
	improvementResult?: SapImprovementResults;
	property: Property;
}

export default function SmartTargetResultItem({
	improvement,
	improvementResult,
	property,
}: SmartTargetResultItemProps) {
	const improvementName = improvement.improvement.name;
	const currentSapResult = property.fetchCurrentSapValues();

	const fetchIcon = () => {
		// remove any bracketed additions to the name
		const baseImprovementName = improvementName.split("(")[0].trim().toLowerCase();
		switch (baseImprovementName) {
			case "100% low energy lighting":
				return <LightingVector />;
			case "air source heat pump":
				return <AirHeatPumpVector />;
			case "a rated boiler":
				return <BoilerFlameVector />;
			case "block open chimneys":
				return <BlockChimneyVector />;
			case "convert main heating to gas":
				return <ConvertArrowsVector />;
			case "double glazing a-rated":
				return <DoubleGlazingVector />;
			case "draught proofing improvements":
				return <DraughtWindVector />;
			case "external wall insulation":
				return <ExternalWallInsulationVector />;
			case "ground source heat pump":
				return <GroundHeatPumpVector />;
			case "heating controls":
				return <HeatingControlsVector />;
			case "high heat retention storage heaters":
				return <StorageHeatersVector />;
			case "high performance doors":
				return <DoorVector />;
			case "high performance glazing":
				return <PerformanceGlazingVector />;
			case "improve hot water cylinder insulation":
				return <WaterInsulationVector />;
			case "loft insulation to 270mm":
			case "loft insulation to 400mm":
				return <LoftInsulationVector />;
			case "party wall insulation":
				return <PartyWallInsulationVector />;
			case "pv panels":
			case "pv":
				return <PvVector />;
			case "smart floor vents":
				return <FloorVentsVector />;
			case "smart heating controls":
				return <SmartHeatingControlsVector />;
			case "solar water heating":
				return <SolarHeatingVector />;
			case "triple glazing":
				return <TripleGlazingVector />;
			case "under floor insulation":
				return <UnderfloorInsulationVector />;
			case "wall insulation":
				return <WallInsulationVector />;
			default:
				// no icon available
				return undefined;
		}
	};

	/**
	 * Is the difference a positive or negative thing
	 * @param sapImprovementName
	 */
	const compareResult = (sapImprovementName: SmartTargetFields) => {
		if (!currentSapResult || !improvementResult) {
			return null;
		}

		const improvementValue = improvementResult[sapImprovementName];
		const currentValue = currentSapResult[sapImprovementName].current;

		if (currentValue == null) {
			return null;
		} else if (improvementValue === currentValue) {
			return null;
		} else if (sapImprovementName.toLowerCase().endsWith("saving")) {
			return improvementValue === 0 ? null : improvementValue > 0;
		}

		return improvementValue > 0 || !currentSapResult[sapImprovementName].validateAbove;
	};

	/**
	 * Calculate the difference in current SAP values and the improved values
	 * @param sapImprovementName
	 */
	const calcDifference = (sapImprovementName: SmartTargetFields) => {
		if (!currentSapResult || !improvementResult) {
			return null;
		}

		const improvementValue = improvementResult[sapImprovementName];
		const currentValue = currentSapResult[sapImprovementName].current;

		if (currentValue == null) {
			return improvementValue;
		} else if (sapImprovementName.toLowerCase().endsWith("saving")) {
			return improvementValue;
		}
		return improvementValue - currentValue;
	};

	return (
		<CardItem title={improvementName} icon={fetchIcon()} className="smart-target-result-item">
			<div className="result-item-container">
				{Object.keys(currentSapResult).map((improvementType) => {
					const impName = improvementType as SmartTargetFields;
					return (
						<SmartTargetValueChip
							key={improvementType}
							type={impName}
							value={calcDifference(impName)}
							direction={compareResult(impName)}
						/>
					);
				})}
			</div>
		</CardItem>
	);
}
