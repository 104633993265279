import { filter } from "lodash";
import { ChangeEvent, FormEvent, useState } from "react";

import { CheckboxProps, InputOnChangeData, Message, Segment } from "semantic-ui-react";

import SettingsSlidersVector from "../../../assets/SettingsSlidersVector";
import { SmartTargetFilter } from "../../../types/types";
import Button from "../../UI/Button/Button";
import Checkbox from "../../UI/Checkbox/Checkbox";

import "./SmartTargetFilterItem.scss";
import Input from "../../UI/Input/Input";

export interface SmartTargetFilterItemProps {
	filterState: SmartTargetFilter;
	onHideToggle: (isChecked: boolean) => void;
	onIncludeToggle: (isIncluded: boolean) => void;
	onSettingsChange?: (comparison: string | null, value: string | null) => void;
}

/**
 * Available values for the smart target filter. Originally built to accept other comparators such as >/<=, but these
 * were unnecessary and now only use equals.
 */
export const smartTargetComparisons = ["", "<", "<=", "=", ">=", ">"] as const;
export type SmartTargetComparison = (typeof smartTargetComparisons)[number];

export default function SmartTargetFilterItem({ filterState, onHideToggle, onIncludeToggle, onSettingsChange }: SmartTargetFilterItemProps) {
	const [isSettingsOpen, setSettingsOpen] = useState<boolean>(false);
	const [error, setError] = useState("");
	const [includeToggle, setIncludeToggle] = useState(filterState.included ?? false);

	const improvement = filterState.customImprovement;

	const onHideToggleChange = (_event: FormEvent, data: CheckboxProps) => {
		const checkStatus = data.checked ?? false;
		if (!checkStatus) {
			setIncludeToggle(false);
		}
		setSettingsOpen(false);
		onHideToggle(checkStatus);
	};

	const onMustIncludeToggleChange = (_event: FormEvent, _data: CheckboxProps) => {
		onIncludeToggle(!includeToggle);
		if (improvement.accepts_parameters) {
			// prompt for a value too, (recommended but not needed)
			setSettingsOpen(!includeToggle);
		}
		setIncludeToggle(!includeToggle);
	}

	const onValueChange = (_event: ChangeEvent, data: InputOnChangeData) => {
		const inputValue = data.value;
		setError("");
		if (inputValue !== "") {
			const inputNum = Number(inputValue);
			if (isNaN(inputNum)) {
				// the user shouldn't be able to enter non-numeric, but double check for fun
				setError("Value must be a number");
			}

			if (improvement.min && inputNum < improvement.min) {
				setError(`Value must be between ${improvement.min} and ${improvement.max}`);
			} else if (improvement.max && inputNum > improvement.max) {
				setError(`Value must be between ${improvement.min} and ${improvement.max}`);
			}
		}

		onSettingsChange && onSettingsChange("=", inputValue);
	};

	return (
		<div className={"filter-item-container " + (isSettingsOpen && !filterState.hidden ? "filter-item-expanded" : "filter-item-collapsed")}>
			<Segment
				className={
					"filter-item filter-item-upper " + (!filterState.hidden ?? true ? "filter-item-selected" : "")
				}
			>
				<Checkbox onChange={onHideToggleChange} checked={!filterState.hidden ?? true} />
				{improvement.name}
				<div className="float-right-container">
					<Checkbox
						className={improvement.accepts_parameters ? "" : "settings-slider-padding"}
						toggle
						label="Force"
						labelSide="left"
						disabled={filterState.hidden}
						checked={includeToggle}
						onChange={onMustIncludeToggleChange}
					/>
					{improvement.accepts_parameters && (
						<Button onClick={() => setSettingsOpen(!isSettingsOpen)} disabled={filterState.hidden}>
							<SettingsSlidersVector />
						</Button>
					)}
				</div>
			</Segment>
			{improvement.accepts_parameters && (
				<>
					<Segment className={"filter-item-lower"}>
						{improvement.name.split("(")[0].trim()} parameter
						<span>=</span>
						<Input placeholder={"value"} value={filterState.value} onChange={onValueChange} type="number" />
						{improvement.units}
					</Segment>
					{error && <Message error>{error}</Message>}
				</>
			)}
		</div>
	);
}
